<template>
  <section>
    <b-alert
      variant="primary"
      show
    >
      <h4
        class="alert-heading d-flex justify-content-center"
      >
        Atenção: Algumas regras e dicas que irão te auxiliar:
      </h4>
      <div class="alert-body">
        <span>
          1. Não desligar a máquina ou trocar a senha. <br> <br>

          2. Não minerar na VPS, diante disso será desativada automaticamente e não havendo reembolso. <br> <br>

          3. Não usar programa pra limpeza (permitido usar o programa de limpeza SC.Robot do site oficial). <br> <br>

          4. Não alterar o MAC da máquina (Tmac, cmd).

          5. VPN's permitidas: ExpressVPN, Pronton, AVG, Avast, Wids, HMA, SurfEasy, NordVPN(!), Surfshark, Hime.me, VyprVPN, PrivateVPN, InternetAcess. <br> <br>

          6. Horário de Suporte especializado VPS: Segunda à Sexta de 13h às 18h. (Sábado e Domingo não atendemos). <br> <br>

          Qualquer desobediência as regras a VPS é desativada, perante o mal uso não nos responsabilizamos.
        </span>
      </div>
    </b-alert>
    <b-overlay
      id="overlay-background"
      :show="loading"
      variant="transparent"
      opacity="1"
      blur="2px"
      rounded="sm"
    >
      <b-card>
        <b-card-body v-if="!fullMode">
          <b-card-title>
            Minhas VPS e VPN
          </b-card-title>
        </b-card-body>
        <b-row>
          <b-col
            md="3"
            sm="4"
            class="my-1"
          >
            <b-form-group class="mb-0">
              <label
                class="d-inline-block text-sm-left mr-50"
              >Por Página</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-25"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="5"
            sm="8"
            class="my-1"
          >
            <b-form-group
              label="Ordenar Por"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="sortBySelect"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                  class="w-75"
                >
                  <template v-slot:first>
                    <option value="">
                      -- Nenhum --
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                  class="w-25"
                >
                  <option :value="false">
                    Asc
                  </option>
                  <option :value="true">
                    Desc
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            class="my-1"
          >
            <b-form-group
              label="Buscar"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Escreva o que deseja pesquisar."
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Limpar
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mb-1"
          >
            <table-selection-mode
              v-model="selectionMode"
              class="mr-1"
              @selectAll="selectAllRows"
            />

            <table-export
              :items="items"
              :selected="selected"
              class="mr-1"
            />

            <table-hidden-selected
              v-if="selected.length > 0"
              :items="items"
              :selected="selected"
              :accounts="accounts"
              class="mr-1"
              @updateAccounts="accounts = $event"
              @updateItems="items = $event"
              @clear="clearSelected"
            />

            <table-filter
              v-show="selected.length === 0"
              :accounts="accounts"
              class="mr-1"
              @update="items = $event"
            />

            <full-mode
              v-model="fullMode"
              class="mr-1"
            />
          </b-col>

          <b-col cols="12">
            <b-table
              v-if="showTable"
              ref="selectableTable"
              striped
              hover
              small
              :sticky-header="isMobile"
              no-border-collapse
              selectable
              :select-mode="selectionMode"
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              @row-selected="onRowSelected"
            >
              <template #cell(pos)="data">
                {{ data.item.pos }}
              </template>

              <template #cell(ip)="data">
                <b-button
                  v-clipboard:copy="data.item.ip"
                  v-clipboard:success="onCopy"
                  size="sm"
                  variant="relief-primary"
                >
                  {{ data.item.ip }}
                </b-button>
              </template>

              <template #cell(username)="data">
                <b-button
                  v-clipboard:copy="data.item.username"
                  v-clipboard:success="onCopy"
                  size="sm"
                  variant="relief-primary"
                >
                  {{ data.item.username }}
                </b-button>
              </template>

              <template #cell(password)="data">
                <b-button
                  v-clipboard:copy="data.item.password"
                  v-clipboard:success="onCopy"
                  size="sm"
                  variant="relief-primary"
                >
                  {{ data.item.password }}
                </b-button>
              </template>

              <template #cell(contact)="data">
                <img
                  class="rounded-lg"
                  :src="`${$api_host}/storage/assets/whatsapp.png`"
                  width="35px"
                  @click.stop="openSupport(data.item)"
                >
              </template>

              <template #cell(status)="data">
                <b-button
                  v-if="!data.item.status"
                  size="sm"
                  variant="relief-success"
                >
                  Ativo
                </b-button>
                <b-button
                  v-else
                  size="sm"
                  variant="relief-danger"
                >
                  Expirado
                </b-button>
              </template>

              <template #cell(purchased_at)="data">
                <b-button
                  variant="relief-primary"
                  size="sm"
                >
                  {{ data.item.purchased_at | date }}
                </b-button>
              </template>

              <template #row-details="data">
                <b-card>
                  <span class="font-weight-bolder">Tipo de conta: </span> <span v-text="data.item.option" /> <br>
                  <span class="font-weight-bolder">Usuário: </span> <span v-text="data.item.username" /> <br>
                  <span class="font-weight-bolder">Senha: </span> <span v-text="data.item.password" /> <br>
                  <span class="font-weight-bolder">Email: </span> <span v-text="data.item.email" /> <br>
                  <span class="font-weight-bolder">Senha do Email: </span> <span v-text="data.item.email_password" /> <br>
                </b-card>
              </template>
            </b-table>
          </b-col>

          <b-col cols="12">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </section>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BAlert,
  BCardTitle,
  BTable,
  BButton
} from 'bootstrap-vue'
import IndexMixin from './mixins/IndexMixin'
import TableMixin from './mixins/TableMixin'
import FullMode from './Functions/FullMode.vue'
import TableSelectionMode from './Functions/TableSelectionMode.vue'
import TableHiddenSelected from './Functions/TableHiddenSelected.vue'
import TableExport from './Functions/TableExport.vue'
import TableFilter from './Functions/TableFilter.vue'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BAlert,
    BCardTitle,
    BTable,
    BButton,
    FullMode,
    TableSelectionMode,
    TableHiddenSelected,
    TableExport,
    TableFilter
  },
  mixins: [IndexMixin, TableMixin],
  computed: {
    isMobile () {
      return window.innerWidth < 800
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
