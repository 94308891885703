import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'

export default {
  filters: {
    date (val) {
      return moment(val).format('DD/MM/Y HH:mm:ss')
    }
  },
  data: () => ({
    loading: true,
    fields: [
      {
        key: 'ip',
        label: 'IP / Key',
        sortable: true
      },
      {
        key: 'username',
        label: 'Usuário',
        sortable: true
      },
      {
        key: 'password',
        label: 'senha',
        sortable: true
      },
      {
        key: 'option',
        label: 'Tipo de conta'
      },
      {
        key: 'contact',
        label: 'Suporte'
      },
      {
        key: 'status',
        label: 'status'
      },
      {
        key: 'purchased_at',
        label: 'Data de Compra'
      }
    ],
    accounts: [],
    items: [],
    fullMode: false,
    tableMode: false,
    selectionMode: null
  }),
  watch: {
    selectionMode () {
      this.showTable = false
      this.clearSelected()

      setTimeout(() => {
        this.showTable = true
      }, 100)
    }
  },
  methods: {
    async getAccounts () {
      const url = '/user/services/my_purchases/vps'
      await this.$http.get(url)
        .then(res => {
          this.accounts = res.data.map((item, index) => {
            return {
              ...item,
              pos: index + 1
            }
          })

          this.items = this.accounts.filter(item => item.hidden === 0)
        })
        .finally(() => {
          this.loading = false
        })
    },
    onCopy () {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Texto copiado com sucesso!',
          icon: 'CheckCircleIcon',
          variant: 'success'
        }
      })
    },
    openSupport (item) {
      const contactSupport = item.support_contact
      const wpLink = `https://api.whatsapp.com/send?phone=55${contactSupport}`
      const text = `&text=%F0%9F%92%BB%20%7C%20*Ol%C3%A1!%20Tenho%20uma%20d%C3%BAvida%20/%20quero%20ativar%20a%20minha%20VPS!*%20%0A%F0%9F%8C%90%20%7C%20*Compra%20feita%20atrav%C3%A9s%20do%20site:%20contasinfinity.com.br*%0A%0A%E2%84%B9%EF%B8%8F%20%7C%20*Nome%20de%20usu%C3%A1rio%20da%20minha%20VPS%20%C3%A9*%3A%20${item.username}`

      window.open(wpLink + text)
    }
  },
  async mounted () {
    await this.getAccounts()
  }
}
