<template>
  <b-button
    class="mt-1"
    variant="relief-danger"
    @click.stop="normalExport()"
  >
    {{ selected.length > 0 ? `Exportar [${selected.length}]` : "Exportar" }}
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import FuncTableExport from './mixins/FuncTableExport'

export default {
  components: {
    BButton
  },
  mixins: [FuncTableExport],
  props: {
    selected: {
      default: [],
      required: true
    },
    items: {
      default: [],
      required: true
    }
  }
}
</script>
