export default {
  computed: {
    accounts () {
      return this.selected.length > 0 ? this.selected : this.items
    }
  },
  methods: {
    makeFile (filename, text) {
      const file = document.createElement('a')

      file.href = `data:attachment/text,${encodeURI(text)}`
      file.target = '_blank'
      file.download = filename
      file.click()
    },
    normalExport () {
      this.export('{ip},{usuario},{senha}')
    },
    export (keys) {
      const response = []
      for (const [index] of this.accounts.entries()) {
        response.push(this.interpreter(this.variables(index), `${keys}\n`))
      }

      this.makeFile('contas.txt', response.join(''))
    },
    variables (index) {
      const item = this.accounts[index]
      return {
        '{ip}': item.ip,
        '{usuario}': item.username,
        '{senha}': item.password
      }
    },
    interpreter (variables, text) {
      let newText = text

      for (let i = 0; i < Object.keys(variables).length; i++) {
        newText = newText.replaceAll(
          `${Object.keys(variables)[i]}`,
          `${Object.values(variables)[i]}`
        )
      }

      return newText
    }
  }
}
